<template>
  <v-row class="pa-10">
    <!-- form biodata reseller -->
    <v-col cols="12" md="6">
      <v-card dark rounded="xl">
        <v-card-title class="brown darken-1">
          <v-col>
            <h3>Form Biodata Reseller</h3>
          </v-col>
        </v-card-title>
        <v-card-text>
          <v-col>
            <v-form ref="form" v-model="valid">
              <v-container class="pa-4">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      outlined
                      v-model="member.nama"
                      label="Nama"
                      :rules="nameRules"
                      clearable
                      rounded
                      color="light-blue accent-1"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      outlined
                      v-model="member.alamat"
                      :rules="addressRules"
                      label="Alamat"
                      clearable
                      rounded
                      color="light-blue accent-1"
                    ></v-text-field>
                  </v-col>

                  <v-col class="d-flex justify-center">
                    <v-radio-group v-model="area" @change="changeField" row mandatory>
                      <v-radio
                        label="Dalam Negeri"
                        value="inlander"
                        color="light-blue accent-1"
                      ></v-radio>
                      <v-radio
                        label="Luar Negeri"
                        value="outlander"
                        color="light-blue accent-1"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>

                  <v-col cols="12">
                    <v-scroll-y-transition mode="out-in">
                      <v-autocomplete
                        outlined
                        v-model="member.kota"
                        :items="kota"
                        item-text="kota"
                        label="Kota/Kabupaten"
                        :rules="cityRules"
                        v-if="inlander"
                        rounded
                        clearable
                        color="light-blue accent-1"
                      ></v-autocomplete>
                      <v-text-field
                        label="Luar Negeri"
                        v-if="outlander"
                        v-model="member.kota"
                        :rules="cityRules"
                        color="light-blue accent-1"
                        clearable
                        outlined
                        rounded
                      ></v-text-field>
                    </v-scroll-y-transition>
                  </v-col>

                  <v-col cols="12"> </v-col>

                  <v-col cols="12" md="6">
                    <v-text-field
                      outlined
                      v-model="member.telepon"
                      label="Telepon"
                      :rules="phoneRules"
                      clearable
                      rounded
                      color="light-blue accent-1"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-select
                      outlined
                      :items="status"
                      v-model="member.status"
                      label="Status"
                      :rules="statusRules"
                      rounded
                      color="light-blue accent-1"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-select
                      outlined
                      :items="items"
                      item-text="name"
                      v-model="sel_membership"
                      label="Keanggotaan"
                      @change="makeLink"
                      :rules="membershipRules"
                      rounded
                      color="light-blue accent-1"
                    ></v-select>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      readonly
                      disabled
                      outlined
                      rounded
                      v-model="member.link"
                      label="Link Reseller"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-col>
        </v-card-text>
      </v-card>
    </v-col>

    <!-- form link online shop reseller -->
    <v-col cols="12" md="6">
      <v-card dark rounded="xl">
        <v-card-title class="brown darken-1">
          <v-col>
            <h3>Form Link Reseller</h3>
          </v-col>
        </v-card-title>
        <v-card-text>
            <v-col>
              <v-form ref="form_links">
                <v-container class="pa-4">
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field
                        outlined
                        v-model="member.url_tokopedia"
                        label="Link Tokopedia"
                        rounded
                        clearable
                        color="light-blue accent-1"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="6">
                      <v-text-field
                        outlined
                        v-model="member.url_bukalapak"
                        label="Link Bukalapak"
                        rounded
                        clearable
                        color="light-blue accent-1"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="6">
                      <v-text-field
                        outlined
                        v-model="member.url_lazada"
                        label="Link Lazada"
                        rounded
                        clearable
                        color="light-blue accent-1"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="6">
                      <v-text-field
                        outlined
                        v-model="member.url_shopee"
                        label="Link Shopee"
                        rounded
                        clearable
                        color="light-blue accent-1"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="6">
                      <v-text-field
                        outlined
                        v-model="member.url_facebook"
                        label="Link Facebook"
                        rounded
                        clearable
                        color="light-blue accent-1"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="6">
                      <v-text-field
                        outlined
                        v-model="member.url_instagram"
                        label="Link Instagram"
                        rounded
                        clearable
                        color="light-blue accent-1"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="6">
                      <v-text-field
                        outlined
                        v-model="member.url_tiktok"
                        label="Link TikTok"
                        rounded
                        clearable
                        color="light-blue accent-1"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-col>
        </v-card-text>
      </v-card>
      <v-card dark class="mt-10 pa-5" rounded="xl">
        <v-card-title class="justify-center">Actions</v-card-title>
        <v-form>
          <v-card-actions>
            <v-col>
              <v-btn
                rounded
                :loading="loading"
                :disabled="loading"
                block
                color="primary"
                @click="postData"
                >simpan
                <v-icon right>mdi-content-save</v-icon>
              </v-btn>
              <v-btn
                rounded
                block
                class="mt-5 black--text"
                color="grey"
                @click="resetForm"
                >Reset <v-icon right>mdi-reload</v-icon></v-btn
              >
            </v-col>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-col>
    <v-snackbar
      v-model="snackbar"
      timeout="2000"
      :color="snackbar_color"
      top
      right
    >
      <v-icon large left>{{ snackbar_icon }}</v-icon>
      {{ snackbar_text }}

      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </v-row>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      disable_city: true,
      disable_foreign_city: true,
      complete: false,
      loading: false,
      snackbar: false,
      snackbar_text: null,
      snackbar_color: null,
      snackbar_icon: null,
      valid: true,
      status: ["Aktif", "Tidak Aktif"],
      items: [
        { value: "agent", name: "Agen" },
        { value: "reseller", name: "Reseller" },
      ],
      sel_membership: null,
      kota: [],
      area: null,
      inlander: false,
      outlander: false,
      member: {
        username: null,
        nama: null,
        alamat: null,
        kota: null,
        telepon: null,
        status: null,
        link: null,
        url_tokopedia: null,
        url_bukalapak: null,
        url_lazada: null,
        url_shopee: null,
        url_facebook: null,
        url_instagram: null,
        url_tiktok: null,
      },
      nameRules: [
        (v) => !!v || "Field Nama diperlukan",
        (v) =>
          (v && v.length >= 3) || "Nama harus memiliki setidaknya 5 karakter",
      ],
      addressRules: [(v) => !!v || "Field Alamat diperlukan"],
      cityRules: [(v) => !!v || "Field Kota diperlukan"],
      phoneRules: [(v) => !!v || "Field Nomor Telepon diperlukan"],
      statusRules: [(v) => !!v || "Field Status diperlukan"],
      membershipRules: [(v) => !!v || "Field Keanggotaan diperlukan"],
    };
  },
  methods: {
    changeField() {
      if (this.area === "inlander") {
        console.log("reseller are inlander");
        this.disable_foreign_city = true;
        this.disable_city = false;
        this.inlander = true;
        this.outlander = false;
      } else {
        console.log("reseller are outlander");
        this.disable_city = true;
        this.disable_foreign_city = false;
        this.outlander = true;
        this.inlander = false;
      }
      console.log(this.member.kota);
    },
    makeLink() {
      if (!this.member.nama) {
        this.snackbar = true;
        this.snackbar_color = "error";
        this.snackbar_icon = "mdi-alert-circle-outline";
        this.snackbar_text = "Nama tidak boleh kosong";
      } else {
        if (this.sel_membership == "agent") {
          this.member.username = `${this.member.nama
            .split(/\s/)
            .join("")}${Math.floor(Math.random() * 99999)}`;
          this.member.link = `https://www.kedasbeautymember.com/member/agent?name=${this.member.username}`;
        } else {
          this.member.username = `${this.member.nama
            .split(/\s/)
            .join("")}${Math.floor(Math.random() * 99999)}`;
          this.member.link = `https://www.kedasbeautymember.com/member/reseller?name=${this.member.username}`;
        }
      }
    },
    async getCities() {
      await axios
        .get(`${process.env.VUE_APP_KB_API_URL}/api/kota`)
        .then((response) => {
          this.kota = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    async postData() {
      this.loading = true;
      let vm = this;
      this.$refs.form.validate();
      console.log(this.valid);
      if (this.valid === true) {
        await axios
          .post(
            `${process.env.VUE_APP_KB_API_URL}/api/reseller`,
            {
              username: this.member.username,
              nama: this.member.nama,
              alamat: this.member.alamat,
              kota: this.member.kota,
              telepon: this.member.telepon,
              status: this.member.status,
              link: this.member.link,
              url_tokopedia: this.member.url_tokopedia,
              url_bukalapak: this.member.url_bukalapak,
              url_lazada: this.member.url_lazada,
              url_shopee: this.member.url_shopee,
              url_facebook: this.member.url_facebook,
              url_instagram: this.member.url_instagram,
              url_tiktok: this.member.url_tiktok,
            },
            {
              headers: {
                Authorization: `Bearer ${window.localStorage.getItem("token")}`,
              },
            }
          )
          .then((response) => {
            console.log(response);
            vm.complete = true;
          })
          .catch(function (error) {
            vm.complete = false;
            console.log(vm.member.link);
            console.log(error.response.data);
            console.log(error.response.status);
          });
          console.log(this.complete)
          console.log(this.username)
      } else {
        this.snackbar = true;
        this.snackbar_text = "The given data was invalid";
        this.snackbar_color = "error";
        this.snackbar_icon = "mdi-alert-circle-outline";
      }
      if (this.complete === true) {
        this.snackbar = true;
        this.snackbar_text = "Data has been saved.";
        this.snackbar_color = "success";
        this.snackbar_icon = "mdi-check";
        this.loading = false;
        setTimeout(() => location.reload(), 1000);
      } else {
        this.snackbar = true;
        this.snackbar_text = "Failed to save data.";
        this.snackbar_color = "error";
        this.snackbar_icon = "mdi-alert-circle-outline";
        this.loading = false;
      }
    },
    resetForm() {
      this.$refs.form.reset();
      this.$refs.form_links.reset();
      this.snackbar = true
      this.snackbar_text = "Form Reseted"
      this.snackbar_color = "info"
      this.snackbar_icon = "mdi-information-variant"
    },
  },
  created() {
    this.getCities();
  },
  computed: {},
};
</script>